
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexgM1Hf56nGTrXdVC38UBzWgOG2b5_45UjcUv2pNm6cbKLoMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/index.vue?macro=true";
import { default as indexOOZvRIrqcMITOWn4Us7jkrL0x6vni_45MaNtF9_dHCsRkMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as start_IqoRx3_455oEg0Flk1UM9ivA0f3J_D8zvqEGFjvuU7UsMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startHpMPhs9HyDTEhviMETyK22CId_m3C3k4IU_p8Nq2m_IMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutTDBdrwmXDIXrhDid3gPaUrowhxORgcs7lTo0us9gz5UMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koD6_Pz671YQi8Z3iKY9J_45_45WXk6QQ_450eCztKoXifJSU_sMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmation5L8v6uE95XrX6u42_45ccPBnp7diCstcf2QWoebuzLu_45EMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationwd6CuXp5TLdtnGUMJJyYxSLouJPTHPLWSjMhlHw3zNUMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyercDUa6LlsngqUa6gVMy7C2N1qAZQfjtQve_REN78u1toMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyer0pj_6zufjCDBeu8BYwu3mvu6LuLpJ1heCobHeWr5380Meta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentCiBn0gvoPWfgWuPwwrg_45lwiNNzo6vjVQg5KJwdbyaCAMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengeRfZQEqYiOpQDASDncrHKWV3SNN43fsKMp8IWl9Rh2UUMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentJhtKdSFc4VOeGS_45yOzpJgbT1jm1Jdx4bA1V4EeJTQl8Meta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta } from "/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexgM1Hf56nGTrXdVC38UBzWgOG2b5_45UjcUv2pNm6cbKLoMeta?.name ?? "index",
    path: indexgM1Hf56nGTrXdVC38UBzWgOG2b5_45UjcUv2pNm6cbKLoMeta?.path ?? "/",
    props: indexgM1Hf56nGTrXdVC38UBzWgOG2b5_45UjcUv2pNm6cbKLoMeta?.props ?? false,
    meta: indexgM1Hf56nGTrXdVC38UBzWgOG2b5_45UjcUv2pNm6cbKLoMeta || {},
    alias: indexgM1Hf56nGTrXdVC38UBzWgOG2b5_45UjcUv2pNm6cbKLoMeta?.alias || [],
    redirect: indexgM1Hf56nGTrXdVC38UBzWgOG2b5_45UjcUv2pNm6cbKLoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/index.vue")
  },
  {
    name: indexOOZvRIrqcMITOWn4Us7jkrL0x6vni_45MaNtF9_dHCsRkMeta?.name ?? "lang",
    path: indexOOZvRIrqcMITOWn4Us7jkrL0x6vni_45MaNtF9_dHCsRkMeta?.path ?? "/:lang?",
    props: indexOOZvRIrqcMITOWn4Us7jkrL0x6vni_45MaNtF9_dHCsRkMeta?.props ?? false,
    meta: indexOOZvRIrqcMITOWn4Us7jkrL0x6vni_45MaNtF9_dHCsRkMeta || {},
    alias: indexOOZvRIrqcMITOWn4Us7jkrL0x6vni_45MaNtF9_dHCsRkMeta?.alias || [],
    redirect: indexOOZvRIrqcMITOWn4Us7jkrL0x6vni_45MaNtF9_dHCsRkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: start_IqoRx3_455oEg0Flk1UM9ivA0f3J_D8zvqEGFjvuU7UsMeta?.name ?? "lang-voucher-start",
    path: start_IqoRx3_455oEg0Flk1UM9ivA0f3J_D8zvqEGFjvuU7UsMeta?.path ?? "/:lang?/voucher/start",
    props: start_IqoRx3_455oEg0Flk1UM9ivA0f3J_D8zvqEGFjvuU7UsMeta?.props ?? false,
    meta: start_IqoRx3_455oEg0Flk1UM9ivA0f3J_D8zvqEGFjvuU7UsMeta || {},
    alias: start_IqoRx3_455oEg0Flk1UM9ivA0f3J_D8zvqEGFjvuU7UsMeta?.alias || [],
    redirect: start_IqoRx3_455oEg0Flk1UM9ivA0f3J_D8zvqEGFjvuU7UsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startHpMPhs9HyDTEhviMETyK22CId_m3C3k4IU_p8Nq2m_IMeta?.name ?? "lang-purchase-start",
    path: startHpMPhs9HyDTEhviMETyK22CId_m3C3k4IU_p8Nq2m_IMeta?.path ?? "/:lang?/purchase/start",
    props: startHpMPhs9HyDTEhviMETyK22CId_m3C3k4IU_p8Nq2m_IMeta?.props ?? false,
    meta: startHpMPhs9HyDTEhviMETyK22CId_m3C3k4IU_p8Nq2m_IMeta || {},
    alias: startHpMPhs9HyDTEhviMETyK22CId_m3C3k4IU_p8Nq2m_IMeta?.alias || [],
    redirect: startHpMPhs9HyDTEhviMETyK22CId_m3C3k4IU_p8Nq2m_IMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutTDBdrwmXDIXrhDid3gPaUrowhxORgcs7lTo0us9gz5UMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutTDBdrwmXDIXrhDid3gPaUrowhxORgcs7lTo0us9gz5UMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutTDBdrwmXDIXrhDid3gPaUrowhxORgcs7lTo0us9gz5UMeta?.props ?? false,
    meta: checkoutTDBdrwmXDIXrhDid3gPaUrowhxORgcs7lTo0us9gz5UMeta || {},
    alias: checkoutTDBdrwmXDIXrhDid3gPaUrowhxORgcs7lTo0us9gz5UMeta?.alias || [],
    redirect: checkoutTDBdrwmXDIXrhDid3gPaUrowhxORgcs7lTo0us9gz5UMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koD6_Pz671YQi8Z3iKY9J_45_45WXk6QQ_450eCztKoXifJSU_sMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koD6_Pz671YQi8Z3iKY9J_45_45WXk6QQ_450eCztKoXifJSU_sMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koD6_Pz671YQi8Z3iKY9J_45_45WXk6QQ_450eCztKoXifJSU_sMeta?.props ?? false,
    meta: koD6_Pz671YQi8Z3iKY9J_45_45WXk6QQ_450eCztKoXifJSU_sMeta || {},
    alias: koD6_Pz671YQi8Z3iKY9J_45_45WXk6QQ_450eCztKoXifJSU_sMeta?.alias || [],
    redirect: koD6_Pz671YQi8Z3iKY9J_45_45WXk6QQ_450eCztKoXifJSU_sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmation5L8v6uE95XrX6u42_45ccPBnp7diCstcf2QWoebuzLu_45EMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmation5L8v6uE95XrX6u42_45ccPBnp7diCstcf2QWoebuzLu_45EMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmation5L8v6uE95XrX6u42_45ccPBnp7diCstcf2QWoebuzLu_45EMeta?.props ?? false,
    meta: confirmation5L8v6uE95XrX6u42_45ccPBnp7diCstcf2QWoebuzLu_45EMeta || {},
    alias: confirmation5L8v6uE95XrX6u42_45ccPBnp7diCstcf2QWoebuzLu_45EMeta?.alias || [],
    redirect: confirmation5L8v6uE95XrX6u42_45ccPBnp7diCstcf2QWoebuzLu_45EMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationwd6CuXp5TLdtnGUMJJyYxSLouJPTHPLWSjMhlHw3zNUMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationwd6CuXp5TLdtnGUMJJyYxSLouJPTHPLWSjMhlHw3zNUMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationwd6CuXp5TLdtnGUMJJyYxSLouJPTHPLWSjMhlHw3zNUMeta?.props ?? false,
    meta: confirmationwd6CuXp5TLdtnGUMJJyYxSLouJPTHPLWSjMhlHw3zNUMeta || {},
    alias: confirmationwd6CuXp5TLdtnGUMJJyYxSLouJPTHPLWSjMhlHw3zNUMeta?.alias || [],
    redirect: confirmationwd6CuXp5TLdtnGUMJJyYxSLouJPTHPLWSjMhlHw3zNUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyercDUa6LlsngqUa6gVMy7C2N1qAZQfjtQve_REN78u1toMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyercDUa6LlsngqUa6gVMy7C2N1qAZQfjtQve_REN78u1toMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyercDUa6LlsngqUa6gVMy7C2N1qAZQfjtQve_REN78u1toMeta?.props ?? false,
    meta: buyercDUa6LlsngqUa6gVMy7C2N1qAZQfjtQve_REN78u1toMeta || {},
    alias: buyercDUa6LlsngqUa6gVMy7C2N1qAZQfjtQve_REN78u1toMeta?.alias || [],
    redirect: buyercDUa6LlsngqUa6gVMy7C2N1qAZQfjtQve_REN78u1toMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyer0pj_6zufjCDBeu8BYwu3mvu6LuLpJ1heCobHeWr5380Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyer0pj_6zufjCDBeu8BYwu3mvu6LuLpJ1heCobHeWr5380Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyer0pj_6zufjCDBeu8BYwu3mvu6LuLpJ1heCobHeWr5380Meta?.props ?? false,
    meta: buyer0pj_6zufjCDBeu8BYwu3mvu6LuLpJ1heCobHeWr5380Meta || {},
    alias: buyer0pj_6zufjCDBeu8BYwu3mvu6LuLpJ1heCobHeWr5380Meta?.alias || [],
    redirect: buyer0pj_6zufjCDBeu8BYwu3mvu6LuLpJ1heCobHeWr5380Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentCiBn0gvoPWfgWuPwwrg_45lwiNNzo6vjVQg5KJwdbyaCAMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentCiBn0gvoPWfgWuPwwrg_45lwiNNzo6vjVQg5KJwdbyaCAMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentCiBn0gvoPWfgWuPwwrg_45lwiNNzo6vjVQg5KJwdbyaCAMeta?.props ?? false,
    meta: paymentCiBn0gvoPWfgWuPwwrg_45lwiNNzo6vjVQg5KJwdbyaCAMeta || {},
    alias: paymentCiBn0gvoPWfgWuPwwrg_45lwiNNzo6vjVQg5KJwdbyaCAMeta?.alias || [],
    redirect: paymentCiBn0gvoPWfgWuPwwrg_45lwiNNzo6vjVQg5KJwdbyaCAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengeRfZQEqYiOpQDASDncrHKWV3SNN43fsKMp8IWl9Rh2UUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengeRfZQEqYiOpQDASDncrHKWV3SNN43fsKMp8IWl9Rh2UUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengeRfZQEqYiOpQDASDncrHKWV3SNN43fsKMp8IWl9Rh2UUMeta?.props ?? false,
    meta: challengeRfZQEqYiOpQDASDncrHKWV3SNN43fsKMp8IWl9Rh2UUMeta || {},
    alias: challengeRfZQEqYiOpQDASDncrHKWV3SNN43fsKMp8IWl9Rh2UUMeta?.alias || [],
    redirect: challengeRfZQEqYiOpQDASDncrHKWV3SNN43fsKMp8IWl9Rh2UUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentJhtKdSFc4VOeGS_45yOzpJgbT1jm1Jdx4bA1V4EeJTQl8Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentJhtKdSFc4VOeGS_45yOzpJgbT1jm1Jdx4bA1V4EeJTQl8Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentJhtKdSFc4VOeGS_45yOzpJgbT1jm1Jdx4bA1V4EeJTQl8Meta?.props ?? false,
    meta: paymentJhtKdSFc4VOeGS_45yOzpJgbT1jm1Jdx4bA1V4EeJTQl8Meta || {},
    alias: paymentJhtKdSFc4VOeGS_45yOzpJgbT1jm1Jdx4bA1V4EeJTQl8Meta?.alias || [],
    redirect: paymentJhtKdSFc4VOeGS_45yOzpJgbT1jm1Jdx4bA1V4EeJTQl8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.path ?? "/pt/spas/",
    props: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.props ?? false,
    meta: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta || {},
    alias: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.alias || [],
    redirect: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.path ?? "/pt/spas/comunidad-valenciana/",
    props: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.props ?? false,
    meta: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta || {},
    alias: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.alias || [],
    redirect: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.path ?? "/pt/spas/cordoba/",
    props: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.props ?? false,
    meta: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta || {},
    alias: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.alias || [],
    redirect: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.path ?? "/pt/spas/cordoba/cordoba/",
    props: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.props ?? false,
    meta: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta || {},
    alias: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.alias || [],
    redirect: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.path ?? "/pt/spas/cordoba/lucena/",
    props: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.props ?? false,
    meta: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta || {},
    alias: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.alias || [],
    redirect: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.path ?? "/pt/spas/valencia/",
    props: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.props ?? false,
    meta: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta || {},
    alias: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.alias || [],
    redirect: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.path ?? "/pt/spas/valencia/gandia/",
    props: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.props ?? false,
    meta: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta || {},
    alias: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.alias || [],
    redirect: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.path ?? "/pt/spas/valencia/paterna/",
    props: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.props ?? false,
    meta: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta || {},
    alias: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.alias || [],
    redirect: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.path ?? "/pt/spas/valencia/valencia/",
    props: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.props ?? false,
    meta: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta || {},
    alias: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.alias || [],
    redirect: SpaList_46pagea_45g8RBtYiIabW7zqpN1j9d3D_6K6oUZBLscAdqKVV0YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/",
    props: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.props ?? false,
    meta: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta || {},
    alias: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.alias || [],
    redirect: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/",
    props: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.props ?? false,
    meta: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta || {},
    alias: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.alias || [],
    redirect: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/",
    props: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.props ?? false,
    meta: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta || {},
    alias: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.alias || [],
    redirect: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/",
    props: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.props ?? false,
    meta: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta || {},
    alias: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.alias || [],
    redirect: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.path ?? "/pt/spas/valencia/spa-boutique-estimar-valencia-4one/",
    props: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.props ?? false,
    meta: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta || {},
    alias: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.alias || [],
    redirect: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.path ?? "/pt/spas/valencia/naital-spa-wellness/",
    props: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.props ?? false,
    meta: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta || {},
    alias: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.alias || [],
    redirect: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.path ?? "/pt/spas/cordoba/vaho-spa/",
    props: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.props ?? false,
    meta: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta || {},
    alias: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.alias || [],
    redirect: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.path ?? "/pt/spas/valencia/hotel-neptuno-spa/",
    props: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.props ?? false,
    meta: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta || {},
    alias: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.alias || [],
    redirect: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/",
    props: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.props ?? false,
    meta: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta || {},
    alias: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.alias || [],
    redirect: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/",
    props: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.props ?? false,
    meta: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta || {},
    alias: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.alias || [],
    redirect: SpaDetail_46pagenmj_45DR1UjsnnCJEzPm0STkkwM0b5tUR12UEhv_45_jO10Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/massagem-oriental-aos-pes/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/terapia-de-perolas-e-ouro/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/rainha-do-egito/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/massagem-samay-30min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/spa-privado-vip-massagem-para-2/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/massagem-pre-natal/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/massagem-as-costas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/cappuccino/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/massagem-shiatsu-de-equilibrio/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/massagem-tailandesa/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/massagem-a-quatro-maos/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/luz-do-mediterraneo/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/ceilao-exotico/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/pedras-de-yucamani/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-spaxion-by-asetra-corte-ingles-colon/hidromassagem-de-essencia/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/massagem-tango/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/massagem-sensorial-da-cabeca-e-do-couro-cabeludo/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/massagem-oriental-spa-1-pax/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/massagem-laranja-e-acesso-ao-spa/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/relaxar-e-desfrutar/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/acesso-ao-tango-massage-and-spa/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/massagem-lomi-lomi-havaiana/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/massagem-balinesa-envolvente/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/relaxar-e-desfrutar-2-pax/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/momento-spa-e-massagem/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/ritual-do-oriente-50/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/massagem-laranja-e-acesso-ao-circuito-spa-1pessoa/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/spa-para-dois-2-pax/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/esfoliacao-sublime-com-monoi-e-tahitian-noni-30-min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/ritual-oriental-80-min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/siam-ritual-80-min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/ritual-ayurvedico-80-min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/ritual-para-jovens-110-min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/relax-enjoy-incluindo-bebidas-110e/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/spa-gastronomico-relaxante-e-almoco-ou-jantar-1-pax/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/banhos-romanos-com-champanhe-para-2/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/feel-bodyna-e79/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/massagem-sensorial-de-cabeca-e-pescoco-30-min-banho-romano-150e1h/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/ritual-brasileiro-creme-de-cafe-adelgacante-e-refirmante-50min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/massagem-oriental-relaxante-30/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/massagem-muscular-relaxante-30/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/sublime-massagem-polinesia-50-min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/massagem-sensorial-da-cabeca-e-do-couro-cabeludo-30/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/ritual-radiance-c/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/noite-romantica/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/circuito-spa-adulto/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/ritual-hidraluronico/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/pureza-ritual/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/massagem-relaxante-50-min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/experiencia-em-reparacao-solar/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/circuito-spa-para-criancas-4-17-anos/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/massagem-localizada-de-tecidos-profundos/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/ritual-da-brisa-de-ganesha/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/ritual-de-bem-estar/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/massagem-ao-por-do-sol-no-egito/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/viagem-a-provenca/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/ritual-de-romance-sol-e-lua/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/viagem-ao-eden-tropical/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-boutique-estimar-valencia-4one/spa-privado-e-gastronomia-para-2/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-boutique-estimar-valencia-4one/spa-privado-e-pequeno-almoco/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-boutique-estimar-valencia-4one/spa-privado/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-boutique-estimar-valencia-4one/circuito-privado-e-massagem-50-para-2/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-boutique-estimar-valencia-4one/experiencia-de-amigos/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/naital-spa-wellness/circuito-spa-massagem-de-relaxamento/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/naital-spa-wellness/massagem-de-relaxamento-50-min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/naital-spa-wellness/circuito-spa-massagem-de-relaxamento-momento-casal/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/naital-spa-wellness/massagem-facial-rapida/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/naital-spa-wellness/massagem-facial-com-rolos-de-jade/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/naital-spa-wellness/massagem-descontraturante-50-min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/naital-spa-wellness/circuito-spa-1-adulto-1-crianca/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/naital-spa-wellness/circuito-spa-2-adultos-1-crianca/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/naital-spa-wellness/circuito-spa-2-adultos-2-criancas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/naital-spa-wellness/circuito-de-spa/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/spa-oriental-para-duas-pessoas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/spa-de-bem-estar/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/spa-oriental/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/experiencia-de-spa-para-duas-pessoas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/relax-spa/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/spa-de-bem-estar-para-duas-pessoas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/experiencia-de-spa/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-com-velas-aromaticas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/circuito-hidrotermal/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-especial-para-a-gravidez/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-shiatsu/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/circuito-hidrotermal-para-criancas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-mineral/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-floral-com-pindas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-de-relaxamento/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/hotel-neptuno-spa/circuito-de-spa-1-pessoa/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/hotel-neptuno-spa/circuito-spa-2-pessoas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/hotel-neptuno-spa/spa-e-gastronomia-para-1/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/hotel-neptuno-spa/spa-massagem-e-gastronomia-para-1/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/hotel-neptuno-spa/spa-e-massagem-para-1/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/banho-de-luxo/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-localizada-de-tecidos-profundos/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/cuidados-da-pele-de-luxo-deluxe/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/hindu-craniofacial/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/cuidados-da-pele-de-luxo-premium/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/rituel-du-sensei/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/tecido-profundo-completo/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/privilegio-o-meu-momento-1/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-rainha-do-egito/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/familia-criancas-luxo-1-adulto-1-crianca-spa-massagem/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/circuito-spa-wellness-semana-e-fim-de-semana/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/spa-para-familias-e-criancas-1/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/essencia-pre-natal/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/viagem-para-a-prosperidade-e-o-sucesso/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-para-a-prosperidade-e-o-sucesso/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-amber-dream-de-morjana/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/circuito-de-spa-privado-de-luxo-para-casais/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-homem-jardim-dos-desejos-yang-por-alqvimia-1/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/jardim-dos-desejos-massagem-mulher-yin-por-alqvimia/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/taste-relax-bau-oasis/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/bau-de-casal-do-jardim-dos-desejos-ying-yang-por-alqvimia/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/oud-dream-journey-de-morjana/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/tranquility-body-face-da-comfortzone/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/caixa-privada-viagem-ao-oriente-aroma-de-oud-ambar-da-morjana/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/jardim-dos-desejos-ritual-da-mulher-yin-por-alqvimia/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/viagem-de-sonho-a-ambar-por-morjana/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-tranquility-da-comfortzone/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-com-oleos-essenciais-quentes-1/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-sonho-de-oud-de-morjana/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-a-4-maos/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/spa-privado-noturno/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/chocomassagem-para-casais/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/shiro-abhyanga-ayurveda/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-calm-free/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/spa-privado-de-luxo-90min/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/drenagem-linfatica/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/bau-de-casal-calm-wellness-com-champanhe-e-chocolates/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/momento-spa-gourmet/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/circuito-termal-massagem-20-pack-romantico-para-2-pessoas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/circuito-spa-90-massagem-20-para-2-pessoas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/circuito-spa-90-massagem-40-para-2-pessoas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/circuito-termico/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/circuito-termico-para-2-pessoas/",
    props: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.props ?? false,
    meta: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta || {},
    alias: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.alias || [],
    redirect: ServiceDetail_46pageacL6Nc6sHx01COX8pnP2GeUBw9GeP_45C2wvG185lRXPgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f44bbd25-cbc2-4809-9e02-46f570a74842/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]